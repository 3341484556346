.div {
  text-align: center;
  margin-bottom: 40px;
}

.div h1 {
  font-weight: 250;
}

.div p {
  padding-left: 25%;
  padding-right: 25%;
  color: #636363;
  padding-bottom: 20px;
  line-height: 28px;
  font-size: medium;
}

@media (max-width: 600px) {
  .div p {
    padding-left: 10%;
    padding-right: 10%;
  }
}
