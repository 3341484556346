.Text {
  color: white;
  flex-basis: 100%;
  padding: 20px;
}

.Text p {
  font-size: medium;
  line-height: 25px;
}
