.Navbar {
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  position: fixed;
  transition: 0.4s;
  z-index: 2;
}

.Navbar img {
  width: 100px;
  transition: 0.4s;
}

.scrolled img {
  width: 70px;
}

.scrolled {
  background-color: #000000;
  padding-top: 0;
}

@media (max-width: 500px) {
  .Navbar {
    display: none;
  }
}
