.div {
  display: flex;
  flex-flow: row;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: center;
  transition: 0.4s;
}

.div h2 {
  padding: 20px 30px 30px 20px;
  font-weight: 300;
  line-height: 45px;
  font-size: xx-large;
}
