.TopDiv {
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 0;
  height: 250px;
  margin: 0;
}

.TopDiv p {
  margin: 0;
  line-height: 28px;
  font-size: medium;
}

.TopDiv a {
  text-decoration: none;
  color: white;
}

.div2 {
  display: flex;
  flex-flow: row;
  color: #8cceed;
  margin-left: 10px;
}

.div2 p {
  margin-left: 5px;
}

h1 {
  padding-left: 10%;
  padding-right: 10%;
  font-weight: 200;
}

.text {
  padding-left: 10%;
  padding-right: 10%;
  font-size: large;
  line-height: 36px;
  color: #7d7d7d;
}
