.div {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  margin: 60px 10% 60px 10%;
  color: white;
}

.div h1 {
  padding: 18% 10% 0 10%;
  font-weight: bold;
}

.div h2 {
  padding: 25% 10% 0 10%;
}

.div h4 {
  padding-bottom: 20%;
}
