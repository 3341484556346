.TopDiv {
  background-image: url(../../images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
.Text {
  display: flex;
  flex-flow: column;
  align-items: center;
  color: white;
  padding-top: 130px;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 100px;
  text-align: center;
}

.Text p {
  font-size: large;
}

@media (max-width: 600px) {
  .Text {
    padding: 130px 10% 100px 10%;
  }
}
