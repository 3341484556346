.div {
  background-image: url(../../../images/bg-block1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  text-align: center;
  padding: 40px;
}

.div p {
  margin-top: 50px;
  margin-bottom: 50px;
  line-height: 28px;
  font-size: medium;
  padding-right: 25%;
  padding-left: 25%;
}

.div h1 {
  padding-left: 25%;
  padding-right: 25%;
}

.div button {
  margin-top: 15px;
  margin-bottom: 60px;
}

@media (max-width: 600px) {
  .div h1 {
    padding-left: 10%;
    padding-right: 10%;
  }
  .div p {
    padding-left: 10%;
    padding-right: 10%;
  }
}
