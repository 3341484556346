.topDiv {
  margin: 0 10% 0 10%;
}

.div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div h2 {
  font-weight: 200;
}

.img {
  margin: 15px 2% 40px 0;
}

.button {
  margin-right: 25px;
  text-decoration: none;
}
