.div {
  margin: 80px;
  background-image: url(../../../images/parallellogramSolidBlue.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: row;
}

.leftDiv {
  margin-top: 20px;
  margin-bottom: 100px;
  color: white;
  display: flex;
  flex-flow: column;
  padding: 60px;
  align-items: center;
}

.leftDiv h1 {
  padding-bottom: 30px;
}

.leftDiv button {
  background-color: rgb(0, 172, 248);
  color: white;
  border: none;
  padding: 10px;
  width: 150px;
  border-radius: 2px;
  margin: 5px;
  transition: 0.4s;
  font-size: medium;
  font-weight: bold;
}

.leftDiv button:hover,
.leftDiv button:active,
.leftDiv button.active,
.leftDiv button:focus {
  background-color: #2597cc;
  outline: none;
}

.rightDiv {
  display: flex;
  flex-flow: column;
  background-color: #2597cc;
  border-radius: 4px;
  color: white;
  margin-top: 100px;
  padding: 30px;
  padding-bottom: 60px;
  height: 200px;
  margin-right: 70px;
  margin-left: 70px;
  margin-bottom: 170px;
  align-items: center;
  line-height: 25px;
}

.rightDiv p {
  padding-bottom: 40px;
  line-height: 30px;
}

@media (max-width: 900px) {
  .div {
    margin: 60px;
    padding-top: 50px;
    background-image: url(../../../images/parallellogramSolidBlue.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
  }
  .leftDiv {
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .rightDiv {
    margin-top: 0;
    padding-bottom: 100px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 0;
    margin-bottom: 100px;
  }
  .rightDiv p {
    line-height: 21px;
    padding-bottom: 0;
  }
}

@media (max-width: 500px) {
  .div {
    margin-right: 10%;
    margin-left: 10%;
  }
  .rightDiv {
    padding-bottom: 150px;
  }

  .rightDiv Button {
    padding-bottom: 30px;
  }
}
