.div {
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  background-image: url(../../../images//bg-block2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding-bottom: 20px;
  padding-top: 80px;
}

.div h1 {
  padding: 0 40px 40px 40px;
}

.div img {
  width: 10%;
  margin-top: 70px;
}

@media (max-width: 900px) {
  .div img {
    width: 120px;
  }
}
