.text {
  margin-left: 10%;
  margin-right: 10%;
  font-size: large;
  line-height: 32px;
  color: #7d7d7d;
}

.img {
  margin: 0 10% 0 10%;
  width: 80%;
}
