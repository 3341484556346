.div {
  position: fixed;
  background-color: white;
  transition: 0.4s;
  display: flex;
  flex-flow: column;
  z-index: 2;
}

.Link {
  color: black;
  text-decoration: none;
  padding: 5px;
  font-size: small;
  transition: 0.4s;
}

.Link:hover,
link:active,
link.active {
  color: rgb(0, 172, 248);
}
