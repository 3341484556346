.NavItem {
  margin: 0;
  display: block;
  width: 100%;
  text-align: center;
  transition: 0.4s;
}

.scrolled {
  height: 50px;
}

.NavItem a {
  display: flex;
  color: white;
  height: 50px;
  text-decoration: none;
  font-size: small;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.4s;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
  color: rgb(0, 172, 248);
}

/*
@media (min-width: 500px) {
  .NavItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }
}

  .NavItem a {
    color: white;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavItem a:hover,
  .NavItem a:active,
  .NavItem a.active {
    background-color: #8f5c2c;
    border-bottom: 4px solid #40a4c8;
    color: white;
  }
} */
