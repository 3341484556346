.div {
  display: flex;
  flex-flow: row;
  margin: 0 15% 75px 15%;
}

.div img {
  height: 90%;
  margin-top: 40px;
}

.form {
  display: flex;
  flex-flow: column;
  margin-right: 50px;
}

.form input {
  margin-bottom: 10px;
}
