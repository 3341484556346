.name {
  display: flex;
  flex-flow: row;
  margin-left: 10%;
}

.name h2 {
  font-weight: 200;
  margin-left: 15px;
}
