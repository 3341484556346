.Boxes {
  display: flex;
  flex-flow: row;
}

@media (max-width: 650px) {
  .Boxes {
    display: flex;
    flex-flow: column;
  }
}
