.div {
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
}

.div p {
  margin-left: 10%;
  margin-right: 10%;
  font-size: large;
  line-height: 36px;
  color: #7d7d7d;
}
.div2 p {
  font-size: large;
  line-height: 36px;
  color: #7d7d7d;
  width: 80%;
  margin-left: 2%;
  margin-right: 15%;
  text-align: center;
}

.div2 {
  display: flex;
  flex-flow: row;
  padding: 10px;
  margin-left: 10%;
}

.div2 img {
  height: 80%;
}
