.Toolbar {
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  position: fixed;
  transition: 0.4s;
  z-index: 1;
}

.Toolbar img {
  width: 100px;
  transition: 0.4s;
}

.scrolled img {
  width: 70px;
}

.scrolled {
  background-color: #000000;
  padding-top: 0;
}

.clicked {
  z-index: 1;
  position: fixed;
  margin-top: 68px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 15px 0 15px;
  transition: 0.4s;
}

.clickedScrolled {
  z-index: 1;
  position: fixed;
  margin-top: 50px;
  background-color: black;
  padding: 0 15px 0 15px;
  transition: 0.4s;
}

@media (min-width: 501px) {
  .Toolbar {
    display: none;
  }
}
